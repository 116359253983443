.image-repeater-wrapper {
    padding: 15px 0;
    width: 50%;
    &:nth-of-type(odd) {
        padding-right: 5px;
    }
    &:nth-of-type(even) {
        padding-left: 5px;
    }
    .box-row {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        .files {
            width: 100%;
        }
    }
}