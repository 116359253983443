@import url(https://fonts.googleapis.com/css?family=Lato&subset=latin-ext);
@import url(https://fonts.googleapis.com/css?family=Material+Icons);
@charset "UTF-8";
.fade-enter-active,
.fade-leave-active {
  -webkit-transition-duration: 0.3s;
          transition-duration: 0.3s;
  -webkit-transition-property: opacity;
  transition-property: opacity;
  -webkit-transition-timing-function: ease;
          transition-timing-function: ease; }

.fade-enter,
.fade-leave-active {
  opacity: 0; }

* {
  box-sizing: border-box; }

body {
  margin: 0;
  font-family: 'Lato', Arial, Helvetica; }

.login-wrapper {
  width: 100%;
  min-height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: url(/static/media/bg-login.876eb8be.jpg); }
  .login-wrapper .overlay {
    background-color: rgba(255, 255, 255, 0.75);
    width: 100%;
    min-height: 100%;
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0; }
  .login-wrapper img {
    position: relative;
    z-index: 2;
    width: 75px;
    margin-top: 20px; }
  .login-wrapper .login {
    margin-top: 30px;
    max-width: 400px;
    width: 100%;
    padding: 30px;
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    background-color: white;
    margin-bottom: 20px; }
    .login-wrapper .login h1 {
      margin-bottom: 30px;
      text-align: center;
      font-size: 18px; }
    .login-wrapper .login label {
      font-size: 13px;
      margin-bottom: 10px;
      color: #6d6d6d; }
    .login-wrapper .login span.error {
      color: #e3342f;
      font-size: 16px;
      width: 100%;
      display: block;
      position: relative;
      margin: 0 0 10px 0; }
      .login-wrapper .login span.error:before {
        content: "■";
        position: relative;
        color: #e3342f;
        margin-right: 10px;
        top: -2px; }
    .login-wrapper .login span.success {
      color: #38c172;
      font-size: 16px;
      width: 100%;
      display: block;
      position: relative;
      margin: 0 0 10px 0; }
      .login-wrapper .login span.success:before {
        content: "■";
        position: relative;
        color: #38c172;
        margin-right: 10px;
        top: -2px; }
    .login-wrapper .login input {
      width: 100%;
      padding: 10px 15px;
      margin: 0 0 20px 0;
      border: 1px solid #dedede; }
    .login-wrapper .login button {
      width: 100%;
      margin: 30px 0 0;
      background: #5A503C;
      border: none;
      color: #fff;
      padding: 15px 10px;
      cursor: pointer;
      transition: .2s ease-in-out all;
      -webkit-transition: .2s ease-in-out all;
      -moz-transition: .2s ease-in-out all;
      -ms-transition: .2s ease-in-out all;
      -o-transition: .2s ease-in-out all; }
      .login-wrapper .login button:hover {
        background-color: #7C7855; }

.navigation {
  margin: 10px; }
  .navigation small {
    display: block; }
    .navigation small a {
      text-decoration: none;
      color: #000;
      font-weight: bold;
      -webkit-transition: .5s;
      transition: .5s; }

@charset "UTF-8";
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after {
  content: '';
  content: none; }

q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

* {
  box-sizing: border-box; }

html {
  font-size: 16px; }

body {
  font-family: 'Roboto', sans-serif;
  color: #3c4858;
  overflow-x: hidden; }

img {
  max-width: 100%;
  width: auto;
  height: auto;
  vertical-align: middle; }

a {
  text-decoration: none; }

.wrapper {
  width: 100%;
  position: relative;
  background-color: #f0f0f0;
  min-height: 100vh; }

main {
  padding-top: 4rem;
  position: relative;
  padding-left: 276px;
  margin-left: 1rem;
  margin-right: 1rem;
  -webkit-transition: 0.3s;
  transition: 0.3s; }
  @media (max-width: 576px) {
    main {
      margin-left: 0.5rem;
      margin-right: 0.5rem; } }

h1, h2, h3, h4, h5, h6 {
  font-weight: 500; }

h1 {
  font-size: 3rem; }

h2 {
  font-size: calc(36rem / 16); }

h3 {
  font-size: calc(24rem / 16); }

h4 {
  font-size: calc(20rem / 16); }

h5 {
  font-size: calc(18rem / 16); }

h6 {
  font-size: 1rem; }

p {
  font-weight: 400;
  font-size: 1rem; }

button, input {
  overflow: visible; }

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  border: 0;
  max-width: 100%; }

body::-webkit-scrollbar, .table-body::-webkit-scrollbar {
  width: 0.5em; }

body::-webkit-scrollbar-track, .table-body::-webkit-scrollbar-track {
  -webkit-box-shadow: none; }

body::-webkit-scrollbar-thumb, .table-body::-webkit-scrollbar-thumb {
  background-color: #5A503C;
  outline: none; }

.section-header {
  margin-bottom: 2rem; }

.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 768px) {
  .container {
    width: 750px; } }

@media (min-width: 992px) {
  .container {
    width: 970px; } }

@media (min-width: 1200px) {
  .container {
    width: 1170px; } }

.container-fluid {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  margin-right: -15px;
  margin-left: -15px; }

.row-no-gutters {
  margin-right: 0;
  margin-left: 0; }

.row-no-gutters [class*="col-"] {
  padding-right: 0;
  padding-left: 0; }

.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }

.col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12 {
  float: left; }

.col-xs-12 {
  width: 100%; }

.col-xs-11 {
  width: 91.66666667%; }

.col-xs-10 {
  width: 83.33333333%; }

.col-xs-9 {
  width: 75%; }

.col-xs-8 {
  width: 66.66666667%; }

.col-xs-7 {
  width: 58.33333333%; }

.col-xs-6 {
  width: 50%; }

.col-xs-5 {
  width: 41.66666667%; }

.col-xs-4 {
  width: 33.33333333%; }

.col-xs-3 {
  width: 25%; }

.col-xs-2 {
  width: 16.66666667%; }

.col-xs-1 {
  width: 8.33333333%; }

.col-xs-pull-12 {
  right: 100%; }

.col-xs-pull-11 {
  right: 91.66666667%; }

.col-xs-pull-10 {
  right: 83.33333333%; }

.col-xs-pull-9 {
  right: 75%; }

.col-xs-pull-8 {
  right: 66.66666667%; }

.col-xs-pull-7 {
  right: 58.33333333%; }

.col-xs-pull-6 {
  right: 50%; }

.col-xs-pull-5 {
  right: 41.66666667%; }

.col-xs-pull-4 {
  right: 33.33333333%; }

.col-xs-pull-3 {
  right: 25%; }

.col-xs-pull-2 {
  right: 16.66666667%; }

.col-xs-pull-1 {
  right: 8.33333333%; }

.col-xs-pull-0 {
  right: auto; }

.col-xs-push-12 {
  left: 100%; }

.col-xs-push-11 {
  left: 91.66666667%; }

.col-xs-push-10 {
  left: 83.33333333%; }

.col-xs-push-9 {
  left: 75%; }

.col-xs-push-8 {
  left: 66.66666667%; }

.col-xs-push-7 {
  left: 58.33333333%; }

.col-xs-push-6 {
  left: 50%; }

.col-xs-push-5 {
  left: 41.66666667%; }

.col-xs-push-4 {
  left: 33.33333333%; }

.col-xs-push-3 {
  left: 25%; }

.col-xs-push-2 {
  left: 16.66666667%; }

.col-xs-push-1 {
  left: 8.33333333%; }

.col-xs-push-0 {
  left: auto; }

.col-xs-offset-12 {
  margin-left: 100%; }

.col-xs-offset-11 {
  margin-left: 91.66666667%; }

.col-xs-offset-10 {
  margin-left: 83.33333333%; }

.col-xs-offset-9 {
  margin-left: 75%; }

.col-xs-offset-8 {
  margin-left: 66.66666667%; }

.col-xs-offset-7 {
  margin-left: 58.33333333%; }

.col-xs-offset-6 {
  margin-left: 50%; }

.col-xs-offset-5 {
  margin-left: 41.66666667%; }

.col-xs-offset-4 {
  margin-left: 33.33333333%; }

.col-xs-offset-3 {
  margin-left: 25%; }

.col-xs-offset-2 {
  margin-left: 16.66666667%; }

.col-xs-offset-1 {
  margin-left: 8.33333333%; }

.col-xs-offset-0 {
  margin-left: 0; }

@media (min-width: 768px) {
  .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12 {
    float: left; }
  .col-sm-12 {
    width: 100%; }
  .col-sm-11 {
    width: 91.66666667%; }
  .col-sm-10 {
    width: 83.33333333%; }
  .col-sm-9 {
    width: 75%; }
  .col-sm-8 {
    width: 66.66666667%; }
  .col-sm-7 {
    width: 58.33333333%; }
  .col-sm-6 {
    width: 50%; }
  .col-sm-5 {
    width: 41.66666667%; }
  .col-sm-4 {
    width: 33.33333333%; }
  .col-sm-3 {
    width: 25%; }
  .col-sm-2 {
    width: 16.66666667%; }
  .col-sm-1 {
    width: 8.33333333%; }
  .col-sm-pull-12 {
    right: 100%; }
  .col-sm-pull-11 {
    right: 91.66666667%; }
  .col-sm-pull-10 {
    right: 83.33333333%; }
  .col-sm-pull-9 {
    right: 75%; }
  .col-sm-pull-8 {
    right: 66.66666667%; }
  .col-sm-pull-7 {
    right: 58.33333333%; }
  .col-sm-pull-6 {
    right: 50%; }
  .col-sm-pull-5 {
    right: 41.66666667%; }
  .col-sm-pull-4 {
    right: 33.33333333%; }
  .col-sm-pull-3 {
    right: 25%; }
  .col-sm-pull-2 {
    right: 16.66666667%; }
  .col-sm-pull-1 {
    right: 8.33333333%; }
  .col-sm-pull-0 {
    right: auto; }
  .col-sm-push-12 {
    left: 100%; }
  .col-sm-push-11 {
    left: 91.66666667%; }
  .col-sm-push-10 {
    left: 83.33333333%; }
  .col-sm-push-9 {
    left: 75%; }
  .col-sm-push-8 {
    left: 66.66666667%; }
  .col-sm-push-7 {
    left: 58.33333333%; }
  .col-sm-push-6 {
    left: 50%; }
  .col-sm-push-5 {
    left: 41.66666667%; }
  .col-sm-push-4 {
    left: 33.33333333%; }
  .col-sm-push-3 {
    left: 25%; }
  .col-sm-push-2 {
    left: 16.66666667%; }
  .col-sm-push-1 {
    left: 8.33333333%; }
  .col-sm-push-0 {
    left: auto; }
  .col-sm-offset-12 {
    margin-left: 100%; }
  .col-sm-offset-11 {
    margin-left: 91.66666667%; }
  .col-sm-offset-10 {
    margin-left: 83.33333333%; }
  .col-sm-offset-9 {
    margin-left: 75%; }
  .col-sm-offset-8 {
    margin-left: 66.66666667%; }
  .col-sm-offset-7 {
    margin-left: 58.33333333%; }
  .col-sm-offset-6 {
    margin-left: 50%; }
  .col-sm-offset-5 {
    margin-left: 41.66666667%; }
  .col-sm-offset-4 {
    margin-left: 33.33333333%; }
  .col-sm-offset-3 {
    margin-left: 25%; }
  .col-sm-offset-2 {
    margin-left: 16.66666667%; }
  .col-sm-offset-1 {
    margin-left: 8.33333333%; }
  .col-sm-offset-0 {
    margin-left: 0; } }

@media (min-width: 992px) {
  .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12 {
    float: left; }
  .col-md-12 {
    width: 100%; }
  .col-md-11 {
    width: 91.66666667%; }
  .col-md-10 {
    width: 83.33333333%; }
  .col-md-9 {
    width: 75%; }
  .col-md-8 {
    width: 66.66666667%; }
  .col-md-7 {
    width: 58.33333333%; }
  .col-md-6 {
    width: 50%; }
  .col-md-5 {
    width: 41.66666667%; }
  .col-md-4 {
    width: 33.33333333%; }
  .col-md-3 {
    width: 25%; }
  .col-md-2 {
    width: 16.66666667%; }
  .col-md-1 {
    width: 8.33333333%; }
  .col-md-pull-12 {
    right: 100%; }
  .col-md-pull-11 {
    right: 91.66666667%; }
  .col-md-pull-10 {
    right: 83.33333333%; }
  .col-md-pull-9 {
    right: 75%; }
  .col-md-pull-8 {
    right: 66.66666667%; }
  .col-md-pull-7 {
    right: 58.33333333%; }
  .col-md-pull-6 {
    right: 50%; }
  .col-md-pull-5 {
    right: 41.66666667%; }
  .col-md-pull-4 {
    right: 33.33333333%; }
  .col-md-pull-3 {
    right: 25%; }
  .col-md-pull-2 {
    right: 16.66666667%; }
  .col-md-pull-1 {
    right: 8.33333333%; }
  .col-md-pull-0 {
    right: auto; }
  .col-md-push-12 {
    left: 100%; }
  .col-md-push-11 {
    left: 91.66666667%; }
  .col-md-push-10 {
    left: 83.33333333%; }
  .col-md-push-9 {
    left: 75%; }
  .col-md-push-8 {
    left: 66.66666667%; }
  .col-md-push-7 {
    left: 58.33333333%; }
  .col-md-push-6 {
    left: 50%; }
  .col-md-push-5 {
    left: 41.66666667%; }
  .col-md-push-4 {
    left: 33.33333333%; }
  .col-md-push-3 {
    left: 25%; }
  .col-md-push-2 {
    left: 16.66666667%; }
  .col-md-push-1 {
    left: 8.33333333%; }
  .col-md-push-0 {
    left: auto; }
  .col-md-offset-12 {
    margin-left: 100%; }
  .col-md-offset-11 {
    margin-left: 91.66666667%; }
  .col-md-offset-10 {
    margin-left: 83.33333333%; }
  .col-md-offset-9 {
    margin-left: 75%; }
  .col-md-offset-8 {
    margin-left: 66.66666667%; }
  .col-md-offset-7 {
    margin-left: 58.33333333%; }
  .col-md-offset-6 {
    margin-left: 50%; }
  .col-md-offset-5 {
    margin-left: 41.66666667%; }
  .col-md-offset-4 {
    margin-left: 33.33333333%; }
  .col-md-offset-3 {
    margin-left: 25%; }
  .col-md-offset-2 {
    margin-left: 16.66666667%; }
  .col-md-offset-1 {
    margin-left: 8.33333333%; }
  .col-md-offset-0 {
    margin-left: 0; } }

@media (min-width: 1200px) {
  .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12 {
    float: left; }
  .col-lg-12 {
    width: 100%; }
  .col-lg-11 {
    width: 91.66666667%; }
  .col-lg-10 {
    width: 83.33333333%; }
  .col-lg-9 {
    width: 75%; }
  .col-lg-8 {
    width: 66.66666667%; }
  .col-lg-7 {
    width: 58.33333333%; }
  .col-lg-6 {
    width: 50%; }
  .col-lg-5 {
    width: 41.66666667%; }
  .col-lg-4 {
    width: 33.33333333%; }
  .col-lg-3 {
    width: 25%; }
  .col-lg-2 {
    width: 16.66666667%; }
  .col-lg-1 {
    width: 8.33333333%; }
  .col-lg-pull-12 {
    right: 100%; }
  .col-lg-pull-11 {
    right: 91.66666667%; }
  .col-lg-pull-10 {
    right: 83.33333333%; }
  .col-lg-pull-9 {
    right: 75%; }
  .col-lg-pull-8 {
    right: 66.66666667%; }
  .col-lg-pull-7 {
    right: 58.33333333%; }
  .col-lg-pull-6 {
    right: 50%; }
  .col-lg-pull-5 {
    right: 41.66666667%; }
  .col-lg-pull-4 {
    right: 33.33333333%; }
  .col-lg-pull-3 {
    right: 25%; }
  .col-lg-pull-2 {
    right: 16.66666667%; }
  .col-lg-pull-1 {
    right: 8.33333333%; }
  .col-lg-pull-0 {
    right: auto; }
  .col-lg-push-12 {
    left: 100%; }
  .col-lg-push-11 {
    left: 91.66666667%; }
  .col-lg-push-10 {
    left: 83.33333333%; }
  .col-lg-push-9 {
    left: 75%; }
  .col-lg-push-8 {
    left: 66.66666667%; }
  .col-lg-push-7 {
    left: 58.33333333%; }
  .col-lg-push-6 {
    left: 50%; }
  .col-lg-push-5 {
    left: 41.66666667%; }
  .col-lg-push-4 {
    left: 33.33333333%; }
  .col-lg-push-3 {
    left: 25%; }
  .col-lg-push-2 {
    left: 16.66666667%; }
  .col-lg-push-1 {
    left: 8.33333333%; }
  .col-lg-push-0 {
    left: auto; }
  .col-lg-offset-12 {
    margin-left: 100%; }
  .col-lg-offset-11 {
    margin-left: 91.66666667%; }
  .col-lg-offset-10 {
    margin-left: 83.33333333%; }
  .col-lg-offset-9 {
    margin-left: 75%; }
  .col-lg-offset-8 {
    margin-left: 66.66666667%; }
  .col-lg-offset-7 {
    margin-left: 58.33333333%; }
  .col-lg-offset-6 {
    margin-left: 50%; }
  .col-lg-offset-5 {
    margin-left: 41.66666667%; }
  .col-lg-offset-4 {
    margin-left: 33.33333333%; }
  .col-lg-offset-3 {
    margin-left: 25%; }
  .col-lg-offset-2 {
    margin-left: 16.66666667%; }
  .col-lg-offset-1 {
    margin-left: 8.33333333%; }
  .col-lg-offset-0 {
    margin-left: 0; } }

.clearfix:before, .clearfix:after, .container:before, .container:after, .container-fluid:before, .container-fluid:after, .row:before, .row:after {
  display: table;
  content: " "; }

.clearfix:after, .container:after, .container-fluid:after, .row:after {
  clear: both; }

.center-block {
  display: block;
  margin-right: auto;
  margin-left: auto; }

.pull-right {
  float: right !important; }

.pull-left {
  float: left !important; }

.hide {
  display: none !important; }

.show {
  display: block !important; }

.invisible {
  visibility: hidden; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.hidden {
  display: none !important; }

.affix {
  position: fixed; }

@-ms-viewport {
  width: device-width; }

.visible-xs, .visible-sm, .visible-md, .visible-lg {
  display: none !important; }

.visible-xs-block, .visible-xs-inline, .visible-xs-inline-block, .visible-sm-block, .visible-sm-inline, .visible-sm-inline-block, .visible-md-block, .visible-md-inline, .visible-md-inline-block, .visible-lg-block, .visible-lg-inline, .visible-lg-inline-block {
  display: none !important; }

@media (max-width: 767px) {
  .visible-xs {
    display: block !important; }
  table.visible-xs {
    display: table !important; }
  tr.visible-xs {
    display: table-row !important; }
  th.visible-xs, td.visible-xs {
    display: table-cell !important; } }

@media (max-width: 767px) {
  .visible-xs-block {
    display: block !important; } }

@media (max-width: 767px) {
  .visible-xs-inline {
    display: inline !important; } }

@media (max-width: 767px) {
  .visible-xs-inline-block {
    display: inline-block !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm {
    display: block !important; }
  table.visible-sm {
    display: table !important; }
  tr.visible-sm {
    display: table-row !important; }
  th.visible-sm, td.visible-sm {
    display: table-cell !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-block {
    display: block !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-inline {
    display: inline !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-inline-block {
    display: inline-block !important; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md {
    display: block !important; }
  table.visible-md {
    display: table !important; }
  tr.visible-md {
    display: table-row !important; }
  th.visible-md, td.visible-md {
    display: table-cell !important; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-block {
    display: block !important; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-inline {
    display: inline !important; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-inline-block {
    display: inline-block !important; } }

@media (min-width: 1200px) {
  .visible-lg {
    display: block !important; }
  table.visible-lg {
    display: table !important; }
  tr.visible-lg {
    display: table-row !important; }
  th.visible-lg, td.visible-lg {
    display: table-cell !important; } }

@media (min-width: 1200px) {
  .visible-lg-block {
    display: block !important; } }

@media (min-width: 1200px) {
  .visible-lg-inline {
    display: inline !important; } }

@media (min-width: 1200px) {
  .visible-lg-inline-block {
    display: inline-block !important; } }

@media (max-width: 767px) {
  .hidden-xs {
    display: none !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .hidden-sm {
    display: none !important; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .hidden-md {
    display: none !important; } }

@media (min-width: 1200px) {
  .hidden-lg {
    display: none !important; } }

.visible-print {
  display: none !important; }

@media print {
  .visible-print {
    display: block !important; }
  table.visible-print {
    display: table !important; }
  tr.visible-print {
    display: table-row !important; }
  th.visible-print, td.visible-print {
    display: table-cell !important; } }

.visible-print-block {
  display: none !important; }

@media print {
  .visible-print-block {
    display: block !important; } }

.visible-print-inline {
  display: none !important; }

@media print {
  .visible-print-inline {
    display: inline !important; } }

.visible-print-inline-block {
  display: none !important; }

@media print {
  .visible-print-inline-block {
    display: inline-block !important; } }

@media print {
  .hidden-print {
    display: none !important; } }

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #5A503C;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(39, 60, 176, 0.14), 0 3px 1px -2px rgba(39, 60, 176, 0.2), 0 1px 5px 0 rgba(39, 60, 176, 0.12);
  border-radius: 3px;
  text-align: center;
  cursor: pointer;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  border: none;
  padding: 10px;
  font-size: 1rem;
  font-weight: 400; }
  .btn i {
    margin: 0 10px 0 0; }
  .btn:hover {
    background-color: #7C7855; }
  .btn.medium {
    width: 200px;
    height: 40px;
    line-height: 40px; }
  .btn.large {
    width: 260px;
    height: 60px;
    line-height: 60px;
    font-size: 1.5rem; }
  .btn.x-large {
    width: 100%;
    height: 60px;
    line-height: 60px;
    font-size: 1.5rem; }
  .btn.error {
    background-color: #d32f2f;
    box-shadow: 0 2px 2px 0 rgba(176, 39, 39, 0.14), 0 3px 1px -2px rgba(176, 39, 39, 0.2), 0 1px 5px 0 rgba(176, 39, 39, 0.12); }
    .btn.error:hover {
      background-color: #b11b1b; }
  .btn.done {
    background-color: #55b559;
    box-shadow: 0 2px 2px 0 rgba(39, 176, 46, 0.14), 0 3px 1px -2px rgba(39, 176, 46, 0.2), 0 1px 5px 0 rgba(39, 176, 46, 0.12); }
    .btn.done:hover {
      background-color: #317934; }
  .btn.disable {
    background-color: #999;
    box-shadow: 0 2px 2px 0 rgba(156, 156, 156, 0.14), 0 3px 1px -2px rgba(156, 156, 156, 0.2), 0 1px 5px 0 rgba(156, 156, 156, 0.12);
    cursor: no-drop; }
    .btn.disable:hover {
      background-color: #999; }
  .btn.save {
    background-color: #ff9800;
    box-shadow: 0 2px 2px 0 rgba(194, 103, 17, 0.14), 0 3px 1px -2px rgba(194, 103, 17, 0.2), 0 1px 5px 0 rgba(194, 103, 17, 0.12); }

.buttons-row {
  align-items: center; }

.menu {
  position: fixed;
  height: 100vh;
  max-width: 260px;
  width: 100%;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  -webkit-transition: .3s;
  transition: .3s;
  z-index: 2; }
  @media (max-width: 576px) {
    .menu {
      max-width: 100%; } }
  .menu-wrapper {
    position: relative;
    z-index: 4;
    height: 100%;
    min-height: 500px; }
  .menu h1 {
    text-transform: uppercase;
    display: flex;
    flex-direction: column;
    padding: 1rem 1rem 1.5rem 1rem;
    border-bottom: 1px solid #d8d7d7;
    max-width: calc(280px - 1rem);
    margin-left: auto;
    margin-right: auto; }
    .menu h1 small {
      font-size: .9rem;
      height: 16px; }
    .menu h1 img {
      display: block;
      margin-bottom: 1rem;
      width: 45px !important; }
    .menu h1 small, .menu h1 img {
      width: 150px;
      overflow: hidden;
      -webkit-transition: width .1s;
      transition: width .1s; }
      @media (max-width: 576px) {
        .menu h1 small, .menu h1 img {
          width: 130px; } }
  .menu ul {
    padding: 1rem;
    margin-top: 0.5rem; }
    .menu ul li {
      padding: .35rem;
      width: 100%;
      margin-bottom: 1rem;
      border-radius: 5px;
      cursor: pointer; }
      .menu ul li a {
        font-size: calc(14rem / 16);
        font-weight: 300;
        display: flex;
        align-items: center;
        padding-left: .5rem;
        color: #a0a0a0;
        width: 100%; }
        .menu ul li a i {
          margin-right: 1rem;
          font-size: 27px; }
      .menu ul li.active {
        background-color: #5A503C;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23); }
        .menu ul li.active:hover {
          background-color: #5A503C; }
        .menu ul li.active a {
          color: #ffffff; }
      .menu ul li:hover {
        background-color: #cfcfcf; }
        .menu ul li:hover a {
          color: #ffffff; }
        .menu ul li:hover > ul {
          -webkit-transform: translateX(0);
                  transform: translateX(0);
          position: relative; }
      .menu ul li > ul {
        -webkit-transform: translateX(-300px);
                transform: translateX(-300px);
        padding-top: 5px;
        padding-bottom: 5px;
        position: absolute; }
        .menu ul li > ul li:last-child {
          margin-bottom: 0; }
        .menu ul li > ul li:hover {
          background-color: #9e9e9e; }
        .menu ul li > ul li > ul {
          -webkit-transform: translateX(-300px);
                  transform: translateX(-300px);
          padding-top: 5px;
          padding-bottom: 5px;
          position: absolute; }
          .menu ul li > ul li > ul li:last-child {
            margin-bottom: 0; }
          .menu ul li > ul li > ul li:hover {
            background-color: #686565; }
  .menu .description {
    position: absolute;
    bottom: 1rem;
    left: 1rem;
    display: block;
    color: #a0a0a0;
    font-size: 0.750rem;
    display: flex;
    align-items: center; }
    .menu .description img {
      height: 30px;
      margin-right: 1rem;
      -webkit-transition: .1s;
      transition: .1s;
      width: 21px; }
    .menu .description span {
      width: 120px;
      height: 12px;
      overflow: hidden;
      -webkit-transition: .1s;
      transition: .1s;
      display: block; }
  .menu button.slide-menu {
    background: transparent;
    border: unset;
    position: absolute;
    right: 1rem;
    top: 1rem;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #5A503C;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    padding: 3px 6px;
    border-radius: 5px;
    z-index: 9;
    outline: unset; }
    .menu button.slide-menu i {
      color: #fff;
      font-size: 20px; }
  .menu .menu-bg {
    background-image: url(/static/media/bg-menu.65f229e9.jpg);
    position: absolute;
    z-index: 1;
    height: 100%;
    width: 100%;
    display: block;
    top: 0;
    left: 0;
    background-size: cover;
    background-position: 50%; }
    .menu .menu-bg:after {
      position: absolute;
      z-index: 3;
      width: 100%;
      height: 100%;
      content: "";
      display: block;
      background: #fff;
      opacity: .93; }

.menu-toggle button.slide-menu i {
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg); }

.menu-toggle .menu {
  max-width: 85px; }
  .menu-toggle .menu h1 {
    width: 0;
    border-color: transparent; }
    .menu-toggle .menu h1 img, .menu-toggle .menu h1 small {
      width: 0; }
  .menu-toggle .menu ul {
    position: absolute;
    top: 45px;
    max-width: 85px; }
    .menu-toggle .menu ul li a {
      padding-left: 0;
      font-size: 20px; }
      .menu-toggle .menu ul li a span {
        display: none; }
      .menu-toggle .menu ul li a i {
        margin-right: 0; }
    .menu-toggle .menu ul li > ul {
      padding-left: 0;
      top: 0; }
      .menu-toggle .menu ul li > ul li {
        padding-left: 6px; }
  .menu-toggle .menu .description {
    left: calc(50% - 15px);
    -webkit-transition: .2s;
    transition: .2s; }
    .menu-toggle .menu .description span {
      width: 0; }
    .menu-toggle .menu .description img {
      margin-right: 0; }

.menu-toggle main {
  padding-left: 93px; }

header {
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 1rem;
  padding-bottom: 2rem;
  position: absolute;
  right: 1rem;
  top: 0;
  z-index: 999999999999999999999; }
  @media (max-width: 576px) {
    header {
      margin-right: 40px; } }
  header nav ul {
    display: flex;
    align-items: center; }
  header nav li:first-child {
    padding-right: 1rem; }
  header nav li span {
    color: #5A503C; }
  header nav li i {
    cursor: pointer; }
    header nav li i:hover {
      color: #d32f2f; }

.page-action {
  margin-bottom: 1.5rem; }

.card {
  margin-bottom: 2rem;
  margin-top: 2rem;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(180, 180, 180, 0.23);
  background-color: #fff;
  border-radius: 5px; }
  .card.card-stats .card-header i {
    background-color: #5A503C; }
  .card.card-stats .card-header:after {
    width: calc(100% - 2rem);
    content: ' ';
    height: 1px;
    display: block;
    background-color: #e9e9e9;
    position: absolute;
    bottom: 0; }
  .card.card-posts .card-header p strong {
    margin-top: 0;
    margin-bottom: 0.25rem; }
  .card.card-posts .card-header img {
    width: 80px;
    height: 80px;
    object-fit: cover; }
  .card.card-posts .card-header:after {
    width: calc(100% - 2rem);
    content: ' ';
    height: 1px;
    display: block;
    background-color: #e9e9e9;
    position: absolute;
    bottom: 0; }
  .card.card-posts .card-dest {
    display: flex;
    flex-direction: column;
    align-items: flex-end; }
    .card.card-posts .card-dest a {
      margin-bottom: 1rem; }
    .card.card-posts .card-dest .post-icons i {
      cursor: pointer; }
      .card.card-posts .card-dest .post-icons i:nth-of-type(2):hover {
        color: #d32f2f; }
      .card.card-posts .card-dest .post-icons i:first-of-type:hover {
        color: #5A503C; }
  .card.card-notify {
    color: #fff;
    display: flex;
    align-items: center;
    padding: 1rem;
    position: relative; }
    .card.card-notify.error {
      background-color: #e63939; }
    .card.card-notify.succes {
      background-color: #55b559; }
    .card.card-notify.mood {
      background-color: #ff9e0f; }
    .card.card-notify i {
      font-size: 36px;
      margin-right: 1rem; }
    .card.card-notify .close-modal {
      position: absolute;
      top: 0.5rem;
      right: -0.5rem;
      cursor: pointer; }
      .card.card-notify .close-modal i {
        font-size: 20px;
        color: #e9e9e9a8; }
  .card.card-data .card-header .card-title {
    padding: 1rem;
    background: #5A503C;
    color: #fff;
    width: 100%; }
    .card.card-data .card-header .card-title h5 {
      font-weight: 300;
      margin-bottom: 0.5rem; }
    .card.card-data .card-header .card-title p {
      font-size: 0.75rem; }
  .card.card-data .table-body {
    padding: 1rem 1rem 2rem 1rem;
    overflow-x: auto; }
    .card.card-data .table-body table {
      width: 100%;
      overflow-x: auto; }
      .card.card-data .table-body table .text-warning {
        color: #e63939; }
      .card.card-data .table-body table thead tr th,
      .card.card-data .table-body table thead tr td {
        font-size: 1rem;
        padding: 1rem;
        text-align: left; }
      .card.card-data .table-body table thead tr th {
        border-bottom: 2px solid rgba(0, 0, 0, 0.06); }
      .card.card-data .table-body table tbody tr {
        border-bottom: 1px solid rgba(0, 0, 0, 0.06);
        -webkit-transition: 0.2s;
        transition: 0.2s; }
        .card.card-data .table-body table tbody tr:hover {
          background-color: rgba(117, 117, 117, 0.06); }
        .card.card-data .table-body table tbody tr td {
          font-size: 0.8750rem;
          padding: 1rem;
          vertical-align: middle; }
          .card.card-data .table-body table tbody tr td i {
            cursor: pointer; }
            .card.card-data .table-body table tbody tr td i:first-of-type {
              margin-right: 0.25rem; }
            .card.card-data .table-body table tbody tr td i.delete {
              color: #e63939; }
            .card.card-data .table-body table tbody tr td i:hover {
              color: #5A503C; }
      .card.card-data .table-body table [type="checkbox"]:not(:checked),
      .card.card-data .table-body table [type="checkbox"]:checked {
        position: absolute;
        left: -9999px; }
      .card.card-data .table-body table [type="checkbox"]:not(:checked) + label,
      .card.card-data .table-body table [type="checkbox"]:checked + label {
        position: relative;
        padding-left: 25px;
        cursor: pointer; }
      .card.card-data .table-body table [type="checkbox"]:not(:checked) + label:before,
      .card.card-data .table-body table [type="checkbox"]:checked + label:before {
        content: '';
        position: absolute;
        left: 0;
        top: -5px;
        width: 22px;
        height: 22px;
        background: #fff;
        border-radius: 3px;
        border: 1px solid rgba(0, 0, 0, 0.2);
        box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        background-color: transparent;
        outline: none; }
      .card.card-data .table-body table [type="checkbox"]:not(:checked) + label:after,
      .card.card-data .table-body table [type="checkbox"]:checked + label:after {
        content: '✔';
        position: absolute;
        top: 0px;
        left: 5px;
        font-size: 18px;
        line-height: 0.8;
        color: #e63939;
        -webkit-transition: all .2s;
        transition: all .2s; }
      .card.card-data .table-body table [type="checkbox"]:not(:checked) + label:after {
        opacity: 0;
        -webkit-transform: rotate(360deg) scale(0);
                transform: rotate(360deg) scale(0); }
      .card.card-data .table-body table [type="checkbox"]:checked + label:after {
        opacity: 1;
        -webkit-transform: rotate(0) scale(1);
                transform: rotate(0) scale(1); }
  .card.card-data form {
    padding: 1rem; }
    .card.card-data form .form-group {
      padding-bottom: 10px;
      position: relative;
      margin: 27px 0 0; }
    .card.card-data form .bmd-form-group:not(.has-success):not(.has-danger) [class*=" bmd-label"].bmd-label-floating,
    .card.card-data form .bmd-form-group:not(.has-success):not(.has-danger) [class^=bmd-label].bmd-label-floating {
      color: #aaa; }
      .card.card-data form .bmd-form-group:not(.has-success):not(.has-danger) [class*=" bmd-label"].bmd-label-floating.error,
      .card.card-data form .bmd-form-group:not(.has-success):not(.has-danger) [class^=bmd-label].bmd-label-floating.error {
        color: #d32f2f !important; }
    .card.card-data form .bmd-form-group [class*=" bmd-label"].bmd-label-floating,
    .card.card-data form .bmd-form-group [class^=bmd-label].bmd-label-floating {
      will-change: left, top, contents;
      margin: 0;
      line-height: 1.4;
      font-weight: 400; }
    .card.card-data form .bmd-form-group [class*=" bmd-label"],
    .card.card-data form .bmd-form-group [class^=bmd-label] {
      position: absolute;
      pointer-events: none;
      -webkit-transition: all .3s ease;
      transition: all .3s ease; }
    .card.card-data form .bmd-form-group .checkbox label,
    .card.card-data form .bmd-form-group .radio label,
    .card.card-data form .bmd-form-group label {
      font-size: .875rem; }
    .card.card-data form .bmd-form-group label {
      color: #aaa; }
    .card.card-data form .bmd-form-group .form-control,
    .card.card-data form .bmd-form-group input::-webkit-input-placeholder,
    .card.card-data form .bmd-form-group label {
      line-height: 1.1; }
    .card.card-data form .bmd-form-group .form-control,
    .card.card-data form .bmd-form-group input::-moz-placeholder,
    .card.card-data form .bmd-form-group label {
      line-height: 1.1; }
    .card.card-data form .bmd-form-group .form-control,
    .card.card-data form .bmd-form-group input:-ms-input-placeholder,
    .card.card-data form .bmd-form-group label {
      line-height: 1.1; }
    .card.card-data form .bmd-form-group .form-control,
    .card.card-data form .bmd-form-group input::-ms-input-placeholder,
    .card.card-data form .bmd-form-group label {
      line-height: 1.1; }
    .card.card-data form .bmd-form-group .form-control,
    .card.card-data form .bmd-form-group input::placeholder,
    .card.card-data form .bmd-form-group label {
      line-height: 1.1; }
    .card.card-data form .bmd-label-floating,
    .card.card-data form .bmd-label-placeholder {
      top: .6125rem; }
    .card.card-data form [class*=" bmd-label"],
    .card.card-data form [class^=bmd-label] {
      color: #999; }
    .card.card-data form .form-check,
    .card.card-data form label {
      font-size: 14px;
      line-height: 1.42857;
      color: #aaa;
      font-weight: 400; }
    .card.card-data form .form-control[disabled],
    .card.card-data form .form-group .form-control[disabled],
    .card.card-data form fieldset[disabled] .form-control,
    .card.card-data form fieldset[disabled] .form-group .form-control {
      background-color: transparent;
      cursor: not-allowed;
      color: #aaa;
      border-bottom: 1px dotted #d2d2d2;
      background-repeat: no-repeat; }
    .card.card-data form .bmd-form-group .form-control,
    .card.card-data form .bmd-form-group input::-webkit-input-placeholder,
    .card.card-data form .bmd-form-group label {
      line-height: 1.1; }
    .card.card-data form .bmd-form-group .form-control,
    .card.card-data form .bmd-form-group input::-moz-placeholder,
    .card.card-data form .bmd-form-group label {
      line-height: 1.1; }
    .card.card-data form .bmd-form-group .form-control,
    .card.card-data form .bmd-form-group input:-ms-input-placeholder,
    .card.card-data form .bmd-form-group label {
      line-height: 1.1; }
    .card.card-data form .bmd-form-group .form-control,
    .card.card-data form .bmd-form-group input::-ms-input-placeholder,
    .card.card-data form .bmd-form-group label {
      line-height: 1.1; }
    .card.card-data form .bmd-form-group .form-control,
    .card.card-data form .bmd-form-group input::placeholder,
    .card.card-data form .bmd-form-group label {
      line-height: 1.1; }
    .card.card-data form .form-control.disabled,
    .card.card-data form .form-control:disabled,
    .card.card-data form .form-control[disabled],
    .card.card-data form fieldset[disabled][disabled] .form-control {
      background-image: -webkit-gradient(linear, left top, right top, color-stop(0, #d2d2d2), color-stop(30%, #d2d2d2), color-stop(0, transparent), to(transparent));
      background-image: linear-gradient(90deg, #d2d2d2 0, #d2d2d2 30%, transparent 0, transparent);
      background-repeat: repeat-x;
      background-size: 3px 1px; }
    .card.card-data form .form-control:disabled,
    .card.card-data form .form-control[readonly] {
      background-color: #e9ecef;
      opacity: 1; }
    .card.card-data form .form-control,
    .card.card-data form input::-webkit-input-placeholder,
    .card.card-data form label {
      line-height: 1.1; }
    .card.card-data form .form-control,
    .card.card-data form input::-moz-placeholder,
    .card.card-data form label {
      line-height: 1.1; }
    .card.card-data form .form-control,
    .card.card-data form input:-ms-input-placeholder,
    .card.card-data form label {
      line-height: 1.1; }
    .card.card-data form .form-control,
    .card.card-data form input::-ms-input-placeholder,
    .card.card-data form label {
      line-height: 1.1; }
    .card.card-data form .form-control,
    .card.card-data form input::placeholder,
    .card.card-data form label {
      line-height: 1.1; }
    .card.card-data form .is-filled .bmd-label-floating,
    .card.card-data form .bmd-label-floating {
      top: -1rem;
      left: 0;
      font-size: .6875rem; }
    .card.card-data form .form-control {
      display: block;
      width: 100%;
      padding: .4375rem 0;
      font-size: 1rem;
      height: 36px;
      line-height: 1.5;
      color: #000000;
      background-color: transparent;
      background-clip: padding-box;
      border: 1px solid #fff;
      box-shadow: none;
      -webkit-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
      transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
      outline: none; }
      .card.card-data form .form-control:focus {
        color: #000000;
        background-color: transparent;
        outline: 0; }
    .card.card-data form .form-control {
      background: no-repeat bottom, 50% calc(100% - 1px);
      background-size: 0 100%, 100% 100%;
      border: 0;
      -webkit-transition: background 0s ease-out;
      transition: background 0s ease-out;
      padding-left: 0;
      padding-right: 0;
      border-radius: 0; }
    .card.card-data form .form-control:focus {
      background-size: 100% 100%, 100% 100%;
      -webkit-transition-duration: .3s;
              transition-duration: .3s;
      box-shadow: none; }
    .card.card-data form .form-control,
    .card.card-data form .is-focused .form-control {
      background-image: linear-gradient(0deg, #5A503C 2px, rgba(156, 39, 176, 0) 0), linear-gradient(0deg, #d2d2d2 1px, rgba(209, 209, 209, 0) 0); }
    .card.card-data form textarea {
      min-height: 100px; }
    .card.card-data form .btn {
      margin-top: 1rem;
      margin-right: 0.5rem; }
    .card.card-data form .checkbox {
      max-width: -webkit-max-content;
      max-width: -moz-max-content;
      max-width: max-content;
      display: flex;
      align-items: center;
      position: relative;
      padding-left: 35px;
      height: 25px;
      cursor: pointer;
      font-size: 22px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none; }
      .card.card-data form .checkbox input {
        position: absolute;
        opacity: 0;
        cursor: pointer; }
        .card.card-data form .checkbox input:checked ~ .checkmark {
          background-color: #5A503C;
          -webkit-transition: background-color .2s ease-in-out;
          transition: background-color .2s ease-in-out; }
          .card.card-data form .checkbox input:checked ~ .checkmark:after {
            display: block; }
      .card.card-data form .checkbox .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 25px;
        width: 25px;
        background-color: #eee;
        border-radius: 50%;
        -webkit-transition: 0.2s;
        transition: 0.2s; }
        .card.card-data form .checkbox .checkmark:after {
          content: "";
          position: absolute;
          display: none; }
      .card.card-data form .checkbox:hover input ~ .checkmark {
        background-color: #5A503C; }
    .card.card-data form .checkbox .checkmark:after {
      top: calc(50% - 4px);
      left: calc(50% - 4px);
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: white; }
    .card.card-data form .select {
      font-size: 16px;
      position: relative;
      display: inline-block;
      max-width: 260px;
      width: 100%; }
      .card.card-data form .select select {
        outline: none;
        -webkit-appearance: none;
        display: block;
        padding: .5rem 1rem;
        margin: 0;
        -webkit-transition: border-color 0.2s;
        transition: border-color 0.2s;
        border: 1px solid #d2d2d2;
        border-radius: 5px;
        width: 100%;
        background: #fff;
        color: #555;
        line-height: normal;
        font-family: inherit;
        font-size: inherit;
        line-height: inherit; }
      .card.card-data form .select .arr {
        color: #5A503C;
        font-size: 30px;
        position: absolute;
        right: 1rem;
        top: 2px;
        width: 20px;
        pointer-events: none; }
    .card.card-data form .editor-form .bmd-form-group [class^=bmd-label] {
      position: static !important;
      display: block;
      margin-bottom: 1rem; }
    .card.card-data form .editor-form .ql-toolbar.ql-snow,
    .card.card-data form .editor-form .ql-container.ql-snow {
      border-top: 0;
      border-left: 0;
      border-right: 0;
      border-bottom: 1px solid #ccc; }
  .card.card-data .switching label {
    position: relative !important;
    display: block; }
  .card.card-data .switch {
    width: 130px;
    border: 1px solid #d2d2d2;
    height: 40px;
    display: flex;
    align-items: center;
    cursor: pointer;
    overflow: hidden;
    margin: 0; }
    .card.card-data .switch.active .slide {
      -webkit-transform: translateX(65px);
              transform: translateX(65px);
      background: #5A503C; }
    .card.card-data .switch .slide {
      height: 40px;
      width: 50%;
      background: #aaa;
      -webkit-transition: .2s ease-in-out transform;
      transition: .2s ease-in-out transform;
      box-shadow: -5px 3px 16px -10px rgba(0, 0, 0, 0.75); }
  .card.card-data .repeat-password {
    margin-top: 20px; }
  .card.card-data .avatar {
    background-color: #aaa;
    width: 150px;
    height: 150px;
    margin: 0 auto;
    border: 1px solid #aaa; }
    .card.card-data .avatar img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .card.card-data .upload-column {
    display: flex;
    justify-content: center;
    margin-top: 20px; }
  .card.card-data .upload-btn-wrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;
    text-align: center;
    cursor: pointer; }
    .card.card-data .upload-btn-wrapper .btn {
      padding: 8px 20px;
      margin: 0 auto;
      cursor: pointer; }
  .card.card-data .upload-btn-wrapper input[type=file] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    cursor: pointer; }
  .card-header {
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    position: relative; }
    @media (max-width: 576px) {
      .card-header {
        flex-direction: column; } }
    .card-header .card-over {
      margin-top: -2rem;
      border-radius: 5px;
      box-shadow: 0 3px 6px rgba(160, 160, 160, 0.16), 0 3px 6px rgba(129, 129, 129, 0.23); }
    .card-header i {
      padding: 1.5rem;
      font-size: 30px;
      background: #e63939;
      color: #fff; }
      @media (max-width: 576px) {
        .card-header i {
          margin-bottom: 1rem; } }
    .card-header strong {
      display: block;
      margin-top: 0.5rem;
      font-size: 1.250rem;
      font-weight: 500; }
      @media (min-width: 576px) {
        .card-header strong {
          text-align: right; } }
    .card-header p {
      font-size: .8750rem;
      font-weight: 300; }
  .card-dest {
    padding: 1rem;
    display: flex;
    align-items: center; }
    .card-dest i {
      margin-right: .25rem; }
    .card-dest a {
      font-size: .750rem;
      cursor: pointer; }
  .card-chart {
    padding: 1rem 1rem .5rem 1rem; }
    .card-chart .ct-chart {
      background-color: #fff;
      border-radius: 5px; }

.actions {
  text-align: center; }
  .actions .ico {
    display: inline-block; }
    .actions .ico:last-child {
      margin-right: 0; }
    .actions .ico i {
      cursor: pointer;
      -webkit-transition: .2s ease-in-out color;
      transition: .2s ease-in-out color; }
    .actions .ico.error:hover i {
      color: #d32f2f; }
    .actions .ico.edit:hover i {
      color: #d3b000; }
  .actions .modal {
    position: fixed;
    top: calc(50% - 150px);
    left: calc(50% - 250px);
    height: 300px;
    width: 500px;
    background: #fff;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(180, 180, 180, 0.23);
    border-radius: 5px; }
    .actions .modal h2 {
      padding-bottom: 30px;
      letter-spacing: 6px;
      color: red; }
    .actions .modal p {
      padding-bottom: 10px; }
      .actions .modal p strong {
        font-weight: bold; }
    .actions .modal .icon {
      position: absolute;
      left: 25px;
      background-color: #d32f2f;
      height: 38px;
      width: 38px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      box-shadow: 0 3px 6px rgba(160, 160, 160, 0.16), 0 3px 6px rgba(129, 129, 129, 0.23); }
      .actions .modal .icon i {
        color: #fff; }
    .actions .modal .exit {
      position: absolute;
      right: 25px;
      background: #5A503C;
      height: 38px;
      width: 38px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      box-shadow: 0 3px 6px rgba(160, 160, 160, 0.16), 0 3px 6px rgba(129, 129, 129, 0.23);
      -webkit-transition: 0.3s;
      transition: 0.3s;
      cursor: pointer; }
      .actions .modal .exit:hover {
        background: #7C7855; }
        .actions .modal .exit:hover i {
          -webkit-transform: scale(1.1);
                  transform: scale(1.1); }
      .actions .modal .exit i {
        color: #fff;
        -webkit-transition: .3s;
        transition: .3s; }
    .actions .modal .btn-wrap {
      display: flex;
      justify-content: center;
      padding-top: 10px; }

.drag-slots-wrap {
  margin: 30px 0; }
  .drag-slots-wrap .drag-slot {
    width: 100%;
    height: 200px;
    border: 1px #5A503C dashed;
    margin-bottom: 25px;
    position: relative; }
    .drag-slots-wrap .drag-slot i {
      position: absolute;
      background-color: #5A503C;
      color: #fff;
      right: 10px;
      top: 10px;
      border-radius: 100%;
      padding: 3px 8px;
      -webkit-transition: .2s ease-in-out background-color;
      transition: .2s ease-in-out background-color;
      cursor: pointer; }
      .drag-slots-wrap .drag-slot i:hover {
        background-color: #d32f2f; }

.loader-box {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  background: #fff;
  z-index: 9999; }
  .loader-box.block {
    display: block; }
  .loader-box .loader {
    z-index: 9999999;
    position: fixed;
    top: calc(50% - 60px);
    left: calc(50% - 60px);
    border: 16px solid rgba(196, 196, 196, 0.7);
    border-top: 16px solid #5A503C;
    border-radius: 50%;
    width: 120px;
    height: 120px;
    -webkit-animation: spin 2s linear infinite;
            animation: spin 2s linear infinite; }

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.pagination-bottom {
  margin-bottom: 30px; }
  .pagination-bottom .-pagination {
    z-index: 1;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-wrap: wrap;
    padding: 3px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
    border-top: 2px solid rgba(0, 0, 0, 0.1); }
    .pagination-bottom .-pagination .-previous {
      flex: 1 1;
      text-align: center; }
      .pagination-bottom .-pagination .-previous .-btn[disabled] {
        opacity: .5;
        cursor: default; }
      .pagination-bottom .-pagination .-previous .-btn {
        display: block;
        width: 100%;
        height: 100%;
        border: 0;
        border-radius: 3px;
        padding: 6px;
        font-size: 1em;
        color: rgba(0, 0, 0, 0.6);
        background: rgba(0, 0, 0, 0.1);
        -webkit-transition: all .1s ease;
        transition: all .1s ease;
        outline-width: 0; }
    .pagination-bottom .-pagination .-center {
      flex: 1.5 1;
      text-align: center;
      margin-bottom: 0;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-around; }
      .pagination-bottom .-pagination .-center .-pageInfo {
        display: inline-block;
        margin: 3px 10px;
        white-space: nowrap; }
        .pagination-bottom .-pagination .-center .-pageInfo .-pageJump {
          display: inline-block; }
          .pagination-bottom .-pagination .-center .-pageInfo .-pageJump input {
            width: 70px;
            text-align: center;
            border: 1px solid rgba(0, 0, 0, 0.1);
            background: #fff;
            padding: 5px 7px;
            font-size: inherit;
            border-radius: 3px;
            font-weight: normal;
            outline-width: 0; }
    .pagination-bottom .-pagination .-next {
      flex: 1 1;
      text-align: center; }
      .pagination-bottom .-pagination .-next .-btn {
        display: block;
        width: 100%;
        height: 100%;
        border: 0;
        border-radius: 3px;
        padding: 6px;
        font-size: 1em;
        color: rgba(0, 0, 0, 0.6);
        background: rgba(0, 0, 0, 0.1);
        -webkit-transition: all .1s ease;
        transition: all .1s ease;
        cursor: pointer;
        outline-width: 0; }

.image-repeater-wrapper {
  padding: 15px 0;
  width: 50%; }
  .image-repeater-wrapper:nth-of-type(odd) {
    padding-right: 5px; }
  .image-repeater-wrapper:nth-of-type(even) {
    padding-left: 5px; }
  .image-repeater-wrapper .box-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; }
    .image-repeater-wrapper .box-row .files {
      width: 100%; }

.drop-area {
  width: 100%;
  height: 100%;
  min-height: 320px;
  max-height: 320px;
  position: relative; }
  .drop-area .place {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #d8d7d7; }
    .drop-area .place img {
      max-height: 90%;
      max-width: 90%; }
  .drop-area .button-wrapper {
    position: absolute;
    top: 0; }

.ct-label {
  fill: #3c4858;
  color: #3c4858;
  font-size: 0.75rem;
  line-height: 1; }

.ct-chart-line .ct-label,
.ct-chart-bar .ct-label {
  display: block;
  display: flex; }

.ct-chart-pie .ct-label,
.ct-chart-donut .ct-label {
  dominant-baseline: central; }

.ct-label.ct-horizontal.ct-start {
  align-items: flex-end;
  justify-content: flex-start;
  text-align: left;
  text-anchor: start; }

.ct-label.ct-horizontal.ct-end {
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  text-anchor: start; }

.ct-label.ct-vertical.ct-start {
  align-items: flex-end;
  justify-content: flex-end;
  text-align: right;
  text-anchor: end; }

.ct-label.ct-vertical.ct-end {
  align-items: flex-end;
  justify-content: flex-start;
  text-align: left;
  text-anchor: start; }

.ct-chart-bar .ct-label.ct-horizontal.ct-start {
  align-items: flex-end;
  justify-content: center;
  text-align: center;
  text-anchor: start; }

.ct-chart-bar .ct-label.ct-horizontal.ct-end {
  align-items: flex-start;
  justify-content: center;
  text-align: center;
  text-anchor: start; }

.ct-chart-bar.ct-horizontal-bars .ct-label.ct-horizontal.ct-start {
  align-items: flex-end;
  justify-content: flex-start;
  text-align: left;
  text-anchor: start; }

.ct-chart-bar.ct-horizontal-bars .ct-label.ct-horizontal.ct-end {
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  text-anchor: start; }

.ct-chart-bar.ct-horizontal-bars .ct-label.ct-vertical.ct-start {
  align-items: center;
  justify-content: flex-end;
  text-align: right;
  text-anchor: end; }

.ct-chart-bar.ct-horizontal-bars .ct-label.ct-vertical.ct-end {
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  text-anchor: end; }

.ct-grid {
  stroke: #a0a0a0;
  stroke-width: 1px;
  stroke-dasharray: 2px; }

.ct-grid-background {
  fill: none; }

.ct-point {
  stroke-width: 10px;
  stroke-linecap: round; }

.ct-line {
  fill: none;
  stroke-width: 4px; }

.ct-area {
  stroke: none;
  fill-opacity: 0.1; }

.ct-bar {
  fill: none;
  stroke-width: 10px; }

.ct-slice-donut {
  fill: none;
  stroke-width: 60px; }

.ct-series-a .ct-point, .ct-series-a .ct-line, .ct-series-a .ct-bar, .ct-series-a .ct-slice-donut {
  stroke: #e63939; }

.ct-series-a .ct-slice-pie, .ct-series-a .ct-slice-donut-solid, .ct-series-a .ct-area {
  fill: #e63939; }

.ct-series-b .ct-point, .ct-series-b .ct-line, .ct-series-b .ct-bar, .ct-series-b .ct-slice-donut {
  stroke: #f05b4f; }

.ct-series-b .ct-slice-pie, .ct-series-b .ct-slice-donut-solid, .ct-series-b .ct-area {
  fill: #f05b4f; }

.ct-series-c .ct-point, .ct-series-c .ct-line, .ct-series-c .ct-bar, .ct-series-c .ct-slice-donut {
  stroke: #f4c63d; }

.ct-series-c .ct-slice-pie, .ct-series-c .ct-slice-donut-solid, .ct-series-c .ct-area {
  fill: #f4c63d; }

.ct-series-d .ct-point, .ct-series-d .ct-line, .ct-series-d .ct-bar, .ct-series-d .ct-slice-donut {
  stroke: #d17905; }

.ct-series-d .ct-slice-pie, .ct-series-d .ct-slice-donut-solid, .ct-series-d .ct-area {
  fill: #d17905; }

.ct-series-e .ct-point, .ct-series-e .ct-line, .ct-series-e .ct-bar, .ct-series-e .ct-slice-donut {
  stroke: #453d3f; }

.ct-series-e .ct-slice-pie, .ct-series-e .ct-slice-donut-solid, .ct-series-e .ct-area {
  fill: #453d3f; }

.ct-series-f .ct-point, .ct-series-f .ct-line, .ct-series-f .ct-bar, .ct-series-f .ct-slice-donut {
  stroke: #59922b; }

.ct-series-f .ct-slice-pie, .ct-series-f .ct-slice-donut-solid, .ct-series-f .ct-area {
  fill: #59922b; }

.ct-series-g .ct-point, .ct-series-g .ct-line, .ct-series-g .ct-bar, .ct-series-g .ct-slice-donut {
  stroke: #0544d3; }

.ct-series-g .ct-slice-pie, .ct-series-g .ct-slice-donut-solid, .ct-series-g .ct-area {
  fill: #0544d3; }

.ct-series-h .ct-point, .ct-series-h .ct-line, .ct-series-h .ct-bar, .ct-series-h .ct-slice-donut {
  stroke: #6b0392; }

.ct-series-h .ct-slice-pie, .ct-series-h .ct-slice-donut-solid, .ct-series-h .ct-area {
  fill: #6b0392; }

.ct-series-i .ct-point, .ct-series-i .ct-line, .ct-series-i .ct-bar, .ct-series-i .ct-slice-donut {
  stroke: #f05b4f; }

.ct-series-i .ct-slice-pie, .ct-series-i .ct-slice-donut-solid, .ct-series-i .ct-area {
  fill: #f05b4f; }

.ct-series-j .ct-point, .ct-series-j .ct-line, .ct-series-j .ct-bar, .ct-series-j .ct-slice-donut {
  stroke: #dda458; }

.ct-series-j .ct-slice-pie, .ct-series-j .ct-slice-donut-solid, .ct-series-j .ct-area {
  fill: #dda458; }

.ct-series-k .ct-point, .ct-series-k .ct-line, .ct-series-k .ct-bar, .ct-series-k .ct-slice-donut {
  stroke: #eacf7d; }

.ct-series-k .ct-slice-pie, .ct-series-k .ct-slice-donut-solid, .ct-series-k .ct-area {
  fill: #eacf7d; }

.ct-series-l .ct-point, .ct-series-l .ct-line, .ct-series-l .ct-bar, .ct-series-l .ct-slice-donut {
  stroke: #86797d; }

.ct-series-l .ct-slice-pie, .ct-series-l .ct-slice-donut-solid, .ct-series-l .ct-area {
  fill: #86797d; }

.ct-series-m .ct-point, .ct-series-m .ct-line, .ct-series-m .ct-bar, .ct-series-m .ct-slice-donut {
  stroke: #b2c326; }

.ct-series-m .ct-slice-pie, .ct-series-m .ct-slice-donut-solid, .ct-series-m .ct-area {
  fill: #b2c326; }

.ct-series-n .ct-point, .ct-series-n .ct-line, .ct-series-n .ct-bar, .ct-series-n .ct-slice-donut {
  stroke: #6188e2; }

.ct-series-n .ct-slice-pie, .ct-series-n .ct-slice-donut-solid, .ct-series-n .ct-area {
  fill: #6188e2; }

.ct-series-o .ct-point, .ct-series-o .ct-line, .ct-series-o .ct-bar, .ct-series-o .ct-slice-donut {
  stroke: #a748ca; }

.ct-series-o .ct-slice-pie, .ct-series-o .ct-slice-donut-solid, .ct-series-o .ct-area {
  fill: #a748ca; }

.ct-square {
  display: block;
  position: relative;
  width: 100%;
  max-width: 100%;
  hieght: 100%; }
  .ct-square:before {
    display: block;
    float: left;
    content: "";
    width: 0;
    height: 0;
    padding-bottom: 100%; }
  .ct-square:after {
    content: "";
    display: table;
    clear: both; }
  .ct-square > svg {
    display: block;
    position: absolute;
    left: 0;
    top: 0; }

.ct-minor-second {
  display: block;
  position: relative;
  width: 100%;
  max-width: 100%;
  hieght: 100%; }
  .ct-minor-second:before {
    display: block;
    float: left;
    content: "";
    width: 0;
    height: 0;
    padding-bottom: 93.75%; }
  .ct-minor-second:after {
    content: "";
    display: table;
    clear: both; }
  .ct-minor-second > svg {
    display: block;
    position: absolute;
    left: 0;
    top: 0; }

.ct-major-second {
  display: block;
  position: relative;
  width: 100%;
  max-width: 100%;
  hieght: 100%; }
  .ct-major-second:before {
    display: block;
    float: left;
    content: "";
    width: 0;
    height: 0;
    padding-bottom: 88.88889%; }
  .ct-major-second:after {
    content: "";
    display: table;
    clear: both; }
  .ct-major-second > svg {
    display: block;
    position: absolute;
    left: 0;
    top: 0; }

.ct-minor-third {
  display: block;
  position: relative;
  width: 100%;
  max-width: 100%;
  hieght: 100%; }
  .ct-minor-third:before {
    display: block;
    float: left;
    content: "";
    width: 0;
    height: 0;
    padding-bottom: 83.33333%; }
  .ct-minor-third:after {
    content: "";
    display: table;
    clear: both; }
  .ct-minor-third > svg {
    display: block;
    position: absolute;
    left: 0;
    top: 0; }

.ct-major-third {
  display: block;
  position: relative;
  width: 100%;
  max-width: 100%;
  hieght: 100%; }
  .ct-major-third:before {
    display: block;
    float: left;
    content: "";
    width: 0;
    height: 0;
    padding-bottom: 80%; }
  .ct-major-third:after {
    content: "";
    display: table;
    clear: both; }
  .ct-major-third > svg {
    display: block;
    position: absolute;
    left: 0;
    top: 0; }

.ct-perfect-fourth {
  display: block;
  position: relative;
  width: 100%;
  max-width: 100%;
  hieght: 100%; }
  .ct-perfect-fourth:before {
    display: block;
    float: left;
    content: "";
    width: 0;
    height: 0;
    padding-bottom: 75%; }
  .ct-perfect-fourth:after {
    content: "";
    display: table;
    clear: both; }
  .ct-perfect-fourth > svg {
    display: block;
    position: absolute;
    left: 0;
    top: 0; }

.ct-perfect-fifth {
  display: block;
  position: relative;
  width: 100%;
  max-width: 100%;
  hieght: 100%; }
  .ct-perfect-fifth:before {
    display: block;
    float: left;
    content: "";
    width: 0;
    height: 0;
    padding-bottom: 66.66667%; }
  .ct-perfect-fifth:after {
    content: "";
    display: table;
    clear: both; }
  .ct-perfect-fifth > svg {
    display: block;
    position: absolute;
    left: 0;
    top: 0; }

.ct-minor-sixth {
  display: block;
  position: relative;
  width: 100%;
  max-width: 100%;
  hieght: 100%; }
  .ct-minor-sixth:before {
    display: block;
    float: left;
    content: "";
    width: 0;
    height: 0;
    padding-bottom: 62.5%; }
  .ct-minor-sixth:after {
    content: "";
    display: table;
    clear: both; }
  .ct-minor-sixth > svg {
    display: block;
    position: absolute;
    left: 0;
    top: 0; }

.ct-golden-section {
  display: block;
  position: relative;
  width: 100%;
  max-width: 100%;
  hieght: 100%; }
  .ct-golden-section:before {
    display: block;
    float: left;
    content: "";
    width: 0;
    height: 0;
    padding-bottom: 61.8047%; }
  .ct-golden-section:after {
    content: "";
    display: table;
    clear: both; }
  .ct-golden-section > svg {
    display: block;
    position: absolute;
    left: 0;
    top: 0; }

.ct-major-sixth {
  display: block;
  position: relative;
  width: 100%;
  max-width: 100%;
  hieght: 100%; }
  .ct-major-sixth:before {
    display: block;
    float: left;
    content: "";
    width: 0;
    height: 0;
    padding-bottom: 60%; }
  .ct-major-sixth:after {
    content: "";
    display: table;
    clear: both; }
  .ct-major-sixth > svg {
    display: block;
    position: absolute;
    left: 0;
    top: 0; }

.ct-minor-seventh {
  display: block;
  position: relative;
  width: 100%;
  max-width: 100%;
  hieght: 100%; }
  .ct-minor-seventh:before {
    display: block;
    float: left;
    content: "";
    width: 0;
    height: 0;
    padding-bottom: 56.25%; }
  .ct-minor-seventh:after {
    content: "";
    display: table;
    clear: both; }
  .ct-minor-seventh > svg {
    display: block;
    position: absolute;
    left: 0;
    top: 0; }

.ct-major-seventh {
  display: block;
  position: relative;
  width: 100%;
  max-width: 100%;
  hieght: 100%; }
  .ct-major-seventh:before {
    display: block;
    float: left;
    content: "";
    width: 0;
    height: 0;
    padding-bottom: 53.33333%; }
  .ct-major-seventh:after {
    content: "";
    display: table;
    clear: both; }
  .ct-major-seventh > svg {
    display: block;
    position: absolute;
    left: 0;
    top: 0; }

.ct-octave {
  display: block;
  position: relative;
  width: 100%;
  max-width: 100%;
  hieght: 100%; }
  .ct-octave:before {
    display: block;
    float: left;
    content: "";
    width: 0;
    height: 0;
    padding-bottom: 50%; }
  .ct-octave:after {
    content: "";
    display: table;
    clear: both; }
  .ct-octave > svg {
    display: block;
    position: absolute;
    left: 0;
    top: 0; }

.ct-major-tenth {
  display: block;
  position: relative;
  width: 100%;
  max-width: 100%;
  hieght: 100%; }
  .ct-major-tenth:before {
    display: block;
    float: left;
    content: "";
    width: 0;
    height: 0;
    padding-bottom: 40%; }
  .ct-major-tenth:after {
    content: "";
    display: table;
    clear: both; }
  .ct-major-tenth > svg {
    display: block;
    position: absolute;
    left: 0;
    top: 0; }

.ct-major-eleventh {
  display: block;
  position: relative;
  width: 100%;
  max-width: 100%;
  hieght: 100%; }
  .ct-major-eleventh:before {
    display: block;
    float: left;
    content: "";
    width: 0;
    height: 0;
    padding-bottom: 37.5%; }
  .ct-major-eleventh:after {
    content: "";
    display: table;
    clear: both; }
  .ct-major-eleventh > svg {
    display: block;
    position: absolute;
    left: 0;
    top: 0; }

.ct-major-twelfth {
  display: block;
  position: relative;
  width: 100%;
  max-width: 100%;
  hieght: 100%; }
  .ct-major-twelfth:before {
    display: block;
    float: left;
    content: "";
    width: 0;
    height: 0;
    padding-bottom: 33.33333%; }
  .ct-major-twelfth:after {
    content: "";
    display: table;
    clear: both; }
  .ct-major-twelfth > svg {
    display: block;
    position: absolute;
    left: 0;
    top: 0; }

.ct-double-octave {
  display: block;
  position: relative;
  width: 100%;
  max-width: 100%;
  hieght: 100%; }
  .ct-double-octave:before {
    display: block;
    float: left;
    content: "";
    width: 0;
    height: 0;
    padding-bottom: 25%; }
  .ct-double-octave:after {
    content: "";
    display: table;
    clear: both; }
  .ct-double-octave > svg {
    display: block;
    position: absolute;
    left: 0;
    top: 0; }

.ReactTable {
  margin: 30px 0 0 0; }
  .ReactTable .rt-th {
    font-size: 15px; }
  .ReactTable .rt-td {
    padding: 11px 10px !important;
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center; }

.editor {
  max-width: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column; }
  .editor .header {
    background: #5A503C;
    color: #fff;
    border-radius: 3px;
    width: 100%;
    padding: 10px 15px;
    border-bottom: 1px solid #d8d7d7; }
  .editor select {
    padding: 10px 25px;
    width: 100%;
    border-bottom: 2px solid rgba(160, 160, 160, 0.16);
    outline: none; }
  .editor input {
    padding: 15px;
    border-bottom: 2px solid rgba(160, 160, 160, 0.16);
    outline: none; }
  .editor .imageInput {
    background: #fff; }
  .editor textarea {
    height: 400px;
    max-height: 400px;
    resize: none;
    box-shadow: 0 3px 6px rgba(160, 160, 160, 0.16), 0 3px 6px rgba(129, 129, 129, 0.23);
    outline: none;
    padding: 15px; }
  .editor .btn-wrap-send {
    padding: 45px 0;
    display: flex;
    justify-content: center; }

.card-box {
  display: flex; }
  @media (max-width: 1100px) {
    .card-box {
      flex-direction: column; } }
  .card-box .upload-locals {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 40%; }
    @media (max-width: 1100px) {
      .card-box .upload-locals {
        width: 100%; } }
    .card-box .upload-locals .size-btn {
      min-width: 300px; }
      @media (max-width: 1270px) {
        .card-box .upload-locals .size-btn {
          min-width: 250px; } }
      @media (max-width: 1100px) {
        .card-box .upload-locals .size-btn {
          min-width: 300px; } }
      @media (max-width: 700px) {
        .card-box .upload-locals .size-btn {
          min-width: 100%; } }
      .card-box .upload-locals .size-btn .btn {
        margin-right: 0; }
    .card-box .upload-locals .input-file-container {
      position: relative; }
      .card-box .upload-locals .input-file-container .input-file-trigger {
        text-align: center;
        display: block;
        padding: 14px 45px;
        background: #2eaa05;
        color: #fff;
        font-size: 1em;
        -webkit-transition: all .4s;
        transition: all .4s;
        cursor: pointer; }
      .card-box .upload-locals .input-file-container .input-file {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        padding: 14px 0;
        cursor: pointer;
        width: 100%; }
      .card-box .upload-locals .input-file-container .input-file:hover + .input-file-trigger,
      .card-box .upload-locals .input-file-container .input-file:focus + .input-file-trigger,
      .card-box .upload-locals .input-file-container .input-file-trigger:hover,
      .card-box .upload-locals .input-file-container .input-file-trigger:focus {
        background: #d3b000;
        color: #fff; }
  .card-box .info {
    width: 60%;
    padding-bottom: 40px; }
    @media (max-width: 1100px) {
      .card-box .info {
        width: 100%; } }
    .card-box .info h2 {
      padding: 15px; }
      @media (max-width: 500px) {
        .card-box .info h2 {
          font-size: 24px; } }
    .card-box .info .pkt {
      display: flex;
      padding: 15px; }
      .card-box .info .pkt span {
        padding-right: 15px;
        font-weight: bold; }
        @media (max-width: 500px) {
          .card-box .info .pkt span {
            padding-right: 5px; } }
      .card-box .info .pkt p a {
        color: #5A503C;
        font-weight: bold; }
        @media (max-width: 500px) {
          .card-box .info .pkt p a {
            font-size: 14px; } }

.inputs-wrapper {
  max-width: 100%;
  background: #fff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  margin-bottom: 50px; }
  .inputs-wrapper .header {
    background: #5A503C;
    color: #fff;
    border-radius: 3px;
    width: 100%;
    padding: 10px 15px;
    border-bottom: 1px solid #d8d7d7; }
  .inputs-wrapper .input-wrapper {
    display: flex;
    border-bottom: 1px solid #d8d7d7; }
    .inputs-wrapper .input-wrapper .number {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 25px;
      border-right: 1px solid #d8d7d7; }
    .inputs-wrapper .input-wrapper .box {
      width: 50%;
      padding: 20px 15px; }
      .inputs-wrapper .input-wrapper .box:nth-child(1) {
        padding-right: 15px; }
      .inputs-wrapper .input-wrapper .box:nth-child(2) {
        padding-left: 15px; }
      .inputs-wrapper .input-wrapper .box .item {
        display: flex;
        flex-direction: column;
        padding-bottom: 15px; }
        .inputs-wrapper .input-wrapper .box .item label {
          margin-bottom: 5px;
          font-size: .875rem;
          line-height: 1.42857;
          color: #aaa;
          font-weight: 400; }
        .inputs-wrapper .input-wrapper .box .item input {
          width: 100%;
          line-height: 1.1;
          background-color: transparent;
          border-bottom: 2px solid #d8d7d7;
          background-repeat: no-repeat;
          outline: none;
          -webkit-transition: .3s;
          transition: .3s;
          padding-bottom: 5px; }
          .inputs-wrapper .input-wrapper .box .item input:focus {
            border-bottom: 2px solid #000; }
  .inputs-wrapper .btn-box {
    display: flex;
    justify-content: center; }
    .inputs-wrapper .btn-box .btn {
      width: 50%; }
      .inputs-wrapper .btn-box .btn:nth-of-type(1) {
        margin-right: 5px; }
      .inputs-wrapper .btn-box .btn:nth-of-type(2) {
        margin-left: 5px; }

.box-wrapper {
  display: flex;
  flex-direction: row; }
  @media (max-width: 750px) {
    .box-wrapper {
      flex-direction: column; } }
  .box-wrapper .box {
    width: 50%; }
    .box-wrapper .box:nth-of-type(1) {
      padding-right: 15px; }
    .box-wrapper .box:nth-of-type(2) {
      padding-left: 15px; }
    @media (max-width: 750px) {
      .box-wrapper .box {
        width: 100%;
        padding: 0 !important; } }
    .box-wrapper .box .color-picker {
      padding: 0 15px;
      display: flex;
      align-items: center; }
      @media (max-width: 1000px) {
        .box-wrapper .box .color-picker {
          flex-direction: column; } }
      .box-wrapper .box .color-picker p {
        padding-left: 15px;
        width: 100%;
        font-size: 36px; }
        @media (max-width: 1000px) {
          .box-wrapper .box .color-picker p {
            font-size: 24px;
            padding-left: 0;
            padding-top: 15px;
            text-align: center; } }

.founding textarea {
  height: 300px;
  max-height: 300px; }

.founding .repeaters {
  display: flex;
  flex-wrap: wrap; }

.founding .inputs-wrapper .btn-box .btn {
  width: 100%;
  margin-right: 0; }

