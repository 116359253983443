.actions {
    text-align: center;
    
    .ico {
        display: inline-block;
        
        &:last-child {
            margin-right: 0;
        }
        i {
            cursor: pointer;
            transition: .2s ease-in-out color;
        }
        &.error {
            &:hover {
                i {
                    color: $errorColor;
                }
            }
        }
        &.edit {
            &:hover {
                i {
                    color: $editColor;
                }
            }
        }
    }
    .modal {
        position: fixed;
        top: calc(50% - 150px);
        left: calc(50% - 250px);
        height: 300px;
        width: 500px;
        background: #fff;
        padding: 1rem;
        display: flex;
        flex-direction: column;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(180, 180, 180, 0.23);
        border-radius: 5px;
        h2 {
            padding-bottom: 30px;
            letter-spacing: 6px;
            color: red;
        }
        p {
            padding-bottom: 10px;
            strong {
                font-weight: bold;
            }
        }
        .icon {
            position: absolute;
            left: 25px;
            background-color: $red;
            height: 38px;
            width: 38px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 5px;
            box-shadow: 0 3px 6px rgba(160, 160, 160, 0.16), 0 3px 6px rgba(129, 129, 129, 0.23);
            i {
                color: #fff;
            }
        }
        .exit {
            position: absolute;
            right: 25px;
            background: #5A503C;
            height: 38px;
            width: 38px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 5px;
            box-shadow: 0 3px 6px rgba(160, 160, 160, 0.16), 0 3px 6px rgba(129, 129, 129, 0.23);
            transition: 0.3s;
            cursor: pointer;
            &:hover {
                background: #7C7855;
                i {
                    transform: scale(1.1);
                }
            }
            i {
                color: #fff;
                transition: .3s;
            }
        }
        .btn-wrap {
            display: flex;
            justify-content: center;
            padding-top: 10px;
        }
    }
}